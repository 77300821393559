/*
Text
*/
@mixin text-wariant($parent, $bg){
  a.#{$parent}:hover{
    background-color: darken($bg, $color-percent);
  }
  a.text-#{$parent}:hover{
    color: darken($bg, $color-percent) !important;
  }
  .text-#{$parent},
  .text-hover-#{$parent} a:hover,
  .text-hover-#{$parent} .active > a{
    color: $bg !important;
  }
}

@include text-wariant(primary, $primary);
@include text-wariant(success, $success);
@include text-wariant(info, $info);
@include text-wariant(warning, $warning);
@include text-wariant(danger, $danger);
@include text-wariant(dark, $dark);
@include text-wariant(blue, $blue);

.text-white {
  color: $full-white !important;
}

.text-color {
  color: $text-color;
}

.text-inherit {
  a{
    color: inherit;
  }
}

.text-fade{
  opacity: 0.65;
}

.text-gd{
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text{
  font-size: 1rem;
}
.text-sm{
  font-size: $font-size-sm;
}
.text-md{
  font-size: $font-size-md;
}
.text-lg{
  font-size: $font-size-lg;
}

.text-2x{
  font-size: 2em !important;
}
.text-3x{
  font-size: 3em !important;
}
.text-4x{
  font-size: 4em !important;
}

.l-h-0{
  line-height: 0;
}
.l-h{
  line-height: $line-height-base;
}
.l-h-1x{
  line-height: 1;
}
.l-h-2x{
  line-height: 2em;
}

.l-s-1x{
  letter-spacing: 1px;
}
.l-s-2x{
  letter-spacing: 2px;
}
.l-s-3x{
  letter-spacing: 3px;
}
.l-s-4x{
  letter-spacing: 4px;
}

.l-s-n-1x{
  letter-spacing: -1px;
}
.l-s-n-2x{
  letter-spacing: -2px;
}
.l-s-n-3x{
  letter-spacing: -3px;
}
.l-s-n-4x{
  letter-spacing: -4px;
}

.h-1x{
  height: $line-height-base * $font-size-base;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.h-2x{
  max-height: $line-height-base * $font-size-base * 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.h-3x{
  max-height: $line-height-base * $font-size-base * 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.h-4x{
  max-height: $line-height-base * $font-size-base * 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-serif{
  font-family: $font-family-serif;
}

.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.text-shadow{
  text-shadow: 0 1px 0 rgba(0,0,0,.1);
}

.font-weight-100{
  font-weight: 100 !important;
}

.font-weight-300{
  font-weight: 300 !important;
}

.font-weight-500{
  font-weight: 500 !important;
}

.font-weight-700{
    font-weight: 700 !important;
}
.font-weight-900{
    font-weight: 900 !important;
}
.text-align-auto{
  text-align: inherit;
}

@include media-breakpoint-down(md) {
  .display-1{
    font-size: 3.5rem;
  }
  .display-2{
    font-size: 3.25rem;
  }
  .display-3{
    font-size: 3rem;
  }
  .display-4{
    font-size: 1.5rem;
  }
  .text-lg {
    font-size: 2rem;
  }
}
