/* @page {
    margin: 0px;
}

@font-face {
    font-family: 'DejaVuSansCondensed';
    src: url('{{url("/storage/fonts/dejavu-sans/DejaVuSansCondensed.ttf")}}') format('truetype');
    font-weight: 200;
}

.base_container {
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}

* {
    margin: 0px;
}

a {
    color: #000;
    text-decoration: none;
} */
@font-face {
    font-family: 'DejaVuSansCondensed';
    src: url('{{url("/storage/fonts/dejavu-sans/DejaVuSansCondensed.ttf")}}') format('truetype');
    font-weight: 200;
}

.base_container {
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}
.invoice_container {
    margin: 15px;
    padding: 5px;
}

.invoice_container .table {
    font-size: x-small;
}

.invoice_container h3,
.invoice_container .h3 {
    font-size: 24px;
}

.invoice_container h4,
.invoice_container  .h4 {
    font-size: 20px;
}



.invoice_container .invoice_header {
    height: 35%;
    /* border-bottom: 1px solid #555; */
    padding: 10px;
    margin-bottom: 10px;

}

.invoice_container .invoice_header .logo {
    width: 60%;
    padding: 10px 0;
    margin-top: 20px;
}

/* .invoice_header div {
            display: inline;
        } */

.invoice_container .invoice_header .billing_container {
    width: 45%;
    height: 25%;
    display: inline-block;
    font-size: 18px;
    margin-top: 50px;
}

.invoice_container .invoice_header .billing_container .invoice {
    border-top: 2px solid #f26523;
    margin-top: 10px;
    padding-top: 15px;
}

.invoice_container .invoice_header .customer_container {
    width: 44%;
    padding-left: 80px;
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 120px;
}

.vertical {
    position: relative;
    background-color: #DDDDDD;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    float: right;
    -moz-transform: rotate(270deg);  /* FF3.5+ */
    -o-transform: rotate(270deg);  /* Opera 10.5 */
    -webkit-transform: rotate(-90deg);  /* Saf3.1+, Chrome */
    -moz-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
}

.invoice_container .invoice_header .barcode_container {
    width: 1%;
    display: inline-block;

    text-align: center;
    margin-top: 180px;
    vertical-align: top;
    font-size: 18px;
}

.invoice_container .invoice_header .bank {
    margin-top: 5px;
    padding-top: 5px;
}

.status {
    position: absolute;
    width: 290px;
    top: 50px;
    right: 170px;
    padding: 10px;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}

.invoice_container .status .unpaid {
    margin: 0;
    border: 4px solid #f26523;
    color: #f26523;
}

.invoice_container .status .paid {
    margin: 0;
    border: 4px solid green;
    color: green;
}

.invoice_container .status .cancelled {
    margin: 0;
    border: 4px solid red;
    color: red;
}

.invoice_container .status .cancelled span {
    clear: both;
    font-size: 18px;
    color: #555;
}

.invoice_container .status .storno {
    margin: 0;
    border: 4px solid #333;
    color: #333;
}

.invoice_container .status .storno span {
    clear: both;
    font-size: 18px;
    color: #333;
}

.invoice_container .body {
    height: 55%;
    margin: 10px;
    /* border: 1px solid #555; */
    margin-top: 40px;
    position: relative;
}

.invoice_container .body table td {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
}

.invoice_container .body table.with-ellipsis td {
    text-overflow: ellipsis;
}

.invoice_container .body table {
    margin: 5px;
    /* border: 1px solid #555; */

}

.invoice_container .body thead th {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    font-size: 18px;
    /* text-transform: uppercase; */
    width: auto;
}

.invoice_container .body tbody tr td {
    padding: 5px;
    border-bottom: 1px solid #666;
}


.invoice_container .body tfoot tr td {
    /* font-weight: bold; */
    font-size: 18px;
    padding-top: 10px;
}

.invoice_container .body .period {
    font-size: 16px;
    color: #555;
}

.invoice_container .body .itemcrt {
    width: 10px;
    /* white-space: pre-line; */
}

.invoice_container .body .item {
    width: auto;
    /* white-space: pre-line; */
}



.invoice_container .body .total {
    position: relative;
    bottom: 10px;
    /* border: 1px solid red; */
    width: 100%;
    /* display: none; */
}

.invoice_container .body .note {
    position: absolute;
    margin-top: 100px;
    bottom: 100px;
    padding: 15px;
    border: 1px solid #555;
    font-size: 15px;
    /* line-height: 25px; */
    width: 100%;
    /* height: 100px; */
}

.invoice_container .body .note .left {
    padding-left: 20px;
}

.invoice_container .borderTop {
    border-top: 1px solid #555;
}

.invoice_container .strong {
    font-weight: bold;
}

.invoice_container .border {
    border: 1px solid #555;
}

.invoice_container .bank {
    /* text-transform: uppercase; */
    padding-bottom: 10px;
}

.invoice_container .center {
    text-align: center;
    vertical-align: middle;
}

.invoice_container .xsmall {
    font-size: 0.5rem;
}

.invoice_container table.invoiceData {
    border-top: 1px solid #555;
    margin: 5px;
}

.invoice_container .nowrap {
    white-space: nowrap;
    margin: 0;
    padding: 0;
}

.invoice_container .uppercase {
    text-transform: uppercase;
}


.invoice_container .footer {
    /* font-size: 75%; */
    position: absolute;
    bottom: 0;
    margin: 5px;
    font-size: 0.55rem;
}

.invoice_container .footer div {
    text-align: center;
    padding: 10px 0;
}

.invoice_container .footer .copyright {
    height: 10px;
}

.invoice_container .footer .left {
    width: 50%;
    margin-left: 2px;
    display: inline-block;
    text-align: left;
}

.invoice_container .footer .right {
    width: 49%;
    display: inline-block;
    text-align: right;
}

.invoice_container .footer .semnatura {
    text-align: center;
    padding: 5px;
}

.invoice_container .barcode {
    max-width: 30px;
    /* border: 1px solid green; */
    margin: 0;
    padding: 0;
    position: relative;
    height: 70px;
    text-align: center;
    vertical-align: top;
}

.invoice_container .muted {
    color: #555;
}

.invoice_container .rotateccw {

    transform: rotate(-90deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.invoice_container .rotatecw {

    transform: rotate(90deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(90deg);
    /* Firefox */
    -moz-transform: rotate(90deg);
    /* IE */
    -ms-transform: rotate(90deg);
    /* Opera */
    -o-transform: rotate(90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);

}
