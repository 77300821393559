

.local .page-sidenav .sidenav {
    background-color: black !important;
}

.feather-10 {
	width: 10px;
	height: 10px;
}

.feather-12 {
	width: 12px;
	height: 12px;
}

.feather-14 {
	width: 14px;
	height: 14px;
}

.feather-16 {
	width: 16px;
	height: 16px;
}

.feather-18 {
	width: 18px;
	height: 18px;
}

.feather-20 {
	width: 20px;
	height: 20px;
}

.feather-22 {
	width: 22px;
	height: 22px;
}

.feather-24 {
	width: 24px;
	height: 24px;
}

.feather-26 {
	width: 26px;
	height: 26px;
}

.feather-28 {
	width: 28px;
	height: 28px;
}

.feather-30 {
	width: 30px;
	height: 30px;
}

.feather-32 {
	width: 32px;
	height: 32px;
}

.feather-64 {
	width: 64px;
	height: 64px;
}

.feather-128 {
	width: 128px;
	height: 128px;
}

/* ###### 3.2 Alerts ###### */

.alert {
	border-width: 0;
	padding: 12px 15px;
	border-radius: 0;
}

.alert .close {
	text-shadow: none;
	opacity: 0.2;
}

.alert strong {
	font-weight: 600;
}

[class*="alert-outline-"] {
	border: 1px solid transparent;
}

.alert-outline-success {
	border-color: #65fe19;
	color: #3bb001;
}

.alert-outline-info {
	border-color: #1ab6cf;
	color: #17a2b8;
}

.alert-outline-warning {
	border-color: #ffdb6d;
	color: #d39e00;
}

.alert-outline-danger {
	border-color: #eb8c95;
	color: #bd2130;
}

[class*="alert-solid-"] {
	border-width: 0;
	color: #fff;
}

.alert-solid-success {
	background-color: #3bb001;
}

.alert-solid-info {
	background-color: #17a2b8;
}

.alert-solid-warning {
	background-color: #ffc107;
}

.alert-solid-danger {
	background-color: #dc3545;
}

.card-invoice {
	background-color: transparent;
	border-width: 0;
}

.card-invoice .card-body {
	padding: 20px;
}

@media (min-width: 992px) {
	.card-invoice .card-body {
		padding: 0 50px 50px;
	}
}

@media (min-width: 768px) {
	.invoice-header {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}
}

.invoice-title {
	margin-bottom: 0;
	text-transform: uppercase;
	color: #cdd4e0;
	font-weight: 700;
}

.billed-from {
	margin-top: 25px;
	margin-bottom: 25px;
}

@media (min-width: 768px) {
	.billed-from {
		margin-top: 0;
	}
}

.billed-from h6 {
	color: #1c273c;
	font-size: 14px;
	font-weight: 700;
}

.billed-from p {
	font-size: 13px;
	margin-bottom: 0;
}

.billed-to h6 {
	color: #1c273c;
	font-size: 14px;
	font-weight: 700;
}

.billed-to p {
	font-size: 13px;
	margin-bottom: 0;
}

.invoice-info-row {
	display: flex;
	justify-content: space-between;
	padding: 5px 0;
	margin-bottom: 0;
	font-size: 13px;
}

.invoice-info-row + .invoice-info-row {
	border-top: 1px dotted #cdd4e0;
}

.invoice-info-row span:first-child {
	color: #596882;
}

.table-invoice {
	margin-top: 20px;
}

.table-invoice thead > tr > th,
.table-invoice thead > tr > td {
	font-size: 11px;
	font-weight: 700;
	color: #7987a1;
}

.table-invoice tbody > tr > th:first-child,
.table-invoice tbody > tr > td:first-child {
	color: #1c273c;
	font-weight: 500;
}

.table-invoice tbody > tr > th:first-child .invoice-notes,
.table-invoice tbody > tr > td:first-child .invoice-notes {
	margin-right: 20px;
}

.table-invoice tbody > tr > th:first-child .invoice-notes .section-label-sm,
.table-invoice tbody > tr > td:first-child .invoice-notes .section-label-sm {
	font-weight: 600;
}

.table-invoice tbody > tr > th:first-child .invoice-notes p,
.table-invoice tbody > tr > td:first-child .invoice-notes p {
	font-size: 13px;
	font-weight: 400;
	color: #031b4e;
}

.parsley-required {
	color: #fc0000;
}

.table-invoice th,
.table-invoice td {
	padding: 0.25rem;
}

.jqvmap-label,
.jqvmap-pin {
	pointer-events: none;
}

.jqvmap-label {
	position: absolute;
	display: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #292929;
	color: #fff;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
	position: absolute;
	left: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #000;
	padding: 3px;
	color: #fff;
	width: 10px;
	height: 10px;
	cursor: pointer;
	line-height: 10px;
	text-align: center;
}

.jqvmap-zoomin {
	top: 10px;
}

.jqvmap-zoomout {
	top: 30px;
}

.jqvmap-region {
	cursor: pointer;
}

.jqvmap-ajax_response {
	width: 100%;
	height: 500px;
}

.ui-check.is-valid > i {
	background-color: none !important;
}

.modal-preview {
	width: 65vw;
}

@media (max-width: 768px) {
	.modal-preview {
		width: 100vw;
	}
}
