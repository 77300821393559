/*
pagination 
*/
.pagination {
  a {
    padding: 0.475rem 0.625rem;
    border: 1px solid $border-color;
  }
  .page-link,
  li a {
    color: inherit !important;
    background-color: #fff !important;
    border-color: $border-color !important;
    display: block;
    &:hover,
    &:focus {
      border-color: $border-color;
      background-color: $min-black;
      color: inherit;
    }
  }
  .active .page-link,
  .active a {
    padding: 0.475rem 0.625rem;
    color: $full-white !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  li:first-child a {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  li:last-child a {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}

.pagination-sm a {
  padding: 0.2195rem 0.5rem;
}

.pagination-xs a {
  padding: 0 0.35rem;
  line-height: 1.5;
}
