@import "_variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/bootstrap.scss";

@import "_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "_bs.scss";
@import "_ui.scss";
@import "_utils.scss";
@import "_md.scss";

// @import "~flag-icon-css/sass/flag-icon";

@import "~select2/dist/css/select2.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~daterangepicker/daterangepicker.css";
@import "css/app";
@import "css/invoice";
