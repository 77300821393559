/*
Layout
*/

.layout,
.layout-row,
.layout-column {
    display: flex;
}

@include media-breakpoint-up(lg) {
    .layout {
        // max-width: 1440px;
          margin: 0 auto;
    }
}

.layout-row {
    flex-direction: row;

    >.flex {
        min-width: 0;
    }
}

.layout-column {
    flex-direction: column;

    >.flex {
        min-height: 0;
    }
}

.flex {
    flex: 1 1 auto;
}

.no-shrink {
    flex-shrink: 0;
}

.no-grow {
    flex-grow: 0;
}

.page-header {
    z-index: 1020;
    transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.sticky~* .page-sidenav.sticky,
    ~* .fixed-content {
        top: $navbar-height;
        height: calc(100vh - #{$navbar-height});
    }

    &.scroll-down {}

    &:not(.sticky).scroll-up {
        position: fixed;
        left: 0;
        right: 0;
    }
}

.page-sidenav {
    position: relative;
    z-index: 1035 !important;
    min-width: $aside-width;
    opacity: 1 !important;

    &.sticky {
        height: 100vh;
    }
}

.is-fullscreen {

    &.layout-column,
    &.layout-row #content {
        display: block;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;

    &.sticky-bottom {
        top: auto;
        bottom: -1px;
    }
}

@include media-breakpoint-up(lg) {
    .page-container {
        // max-width: 1280px;
        // max-width: 1400px;
        margin: 0 auto;
    }

    .page-sidenav {
        display: block !important;
    }
}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {
    .page-sidenav {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        position: fixed;
        z-index: 1050 !important;
        display: none;
        opacity: 0;

        .bg-dark &,
        & {
            background: transparent !important;
        }

        >* {
            position: fixed;
            height: 100%;
            background-color: inherit;
            transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
            transform: translate3d(-100%, 0, 0);
            width: $aside-slide-width;
        }

        &.show {
            >div {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

@media print {

    .page-sidenav,
    .page-header {
        display: none !important;
    }
}
